import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TInvoicesState, TLastInvoice } from './types'

const initialState: TInvoicesState = {
  lastInvoice: null,
  isNextDayWork: true,
}

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setLastInvoice(state: TInvoicesState, action: PayloadAction<TLastInvoice | null>) {
      state.lastInvoice = action.payload
    },
    setIsNextDayWork(state: TInvoicesState, action: PayloadAction<boolean>) {
      state.isNextDayWork = action.payload
    },
  },
})

export const { setLastInvoice, setIsNextDayWork } = invoicesSlice.actions

export default invoicesSlice.reducer
