import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TCatalog, TGenreItem, TMixedPlaylistPhonograms, TPhonogram, TPhonogramInBlacklist, TPlaylist } from './types'
import { LIMIT } from 'constants/limit'

const initialState: TCatalog = {
  playlistsOks: [],
  playlistsLk: [],
  genresList: [],
  phonogramsList: [],
  phonogramsListHasMore: true,
  phonogramsLoadOffset: LIMIT,
  listBlacklistPhonograms: [],
  addingPhonogramsIds: [],
  deletingPhonogramsIds: [],
  playlistID: null,
  playlistById: null,
  playlistPhonograms: [],
  tabIndex: 0,
}

const catalogs = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setPhonogramsList(state: TCatalog, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = action.payload
    },
    setGenreList(state: TCatalog, action: PayloadAction<TGenreItem[]>) {
      state.genresList = [{ id: undefined, name: 'Все жанры', nameTr: 'All genres' }, ...action.payload]
    },
    setPlaylistsOks(state: TCatalog, action: PayloadAction<TPlaylist[]>) {
      state.playlistsOks = action.payload
    },
    setPlaylistsLk(state: TCatalog, action: PayloadAction<TPlaylist[]>) {
      state.playlistsLk = action.payload
    },
    setPhonogramsListOnScroll(state: TCatalog, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = [...state.phonogramsList, ...action.payload]
    },
    setPhonogramsLoadOffset(state: TCatalog, action: PayloadAction<number>) {
      state.phonogramsLoadOffset = action.payload
    },
    setPhonogramsListHasMore(state: TCatalog, action: PayloadAction<boolean>) {
      state.phonogramsListHasMore = action.payload
    },
    setAddPhonogramInBlacklist(state: TCatalog, action: PayloadAction<TMixedPlaylistPhonograms>) {
      state.listBlacklistPhonograms.push(action.payload)
    },
    setRemovePhonogramFromBlacklist(state: TCatalog, action: PayloadAction<string | null>) {
      state.listBlacklistPhonograms = state.listBlacklistPhonograms.filter((item) => item.id !== action.payload)
    },
    setAddingPhonogramIds(state: TCatalog, action: PayloadAction<string>) {
      state.addingPhonogramsIds.push(action.payload)
    },
    setDeletingPhonogramIds(state: TCatalog, action: PayloadAction<string>) {
      state.deletingPhonogramsIds.push(action.payload)
    },
    clearAddingDeletingPhonogramIds(state: TCatalog, action: PayloadAction<[]>) {
      state.addingPhonogramsIds = action.payload
      state.deletingPhonogramsIds = action.payload
    },
    setPhonogramsInBlacklistFromServer(state: TCatalog, action: PayloadAction<Array<TPhonogramInBlacklist>>) {
      state.listBlacklistPhonograms = action.payload
    },
    setPlaylistID(state: TCatalog, action: PayloadAction<string | null>) {
      state.playlistID = action.payload
    },
    setPlaylistById(state: TCatalog, action: PayloadAction<TPlaylist | null>) {
      state.playlistById = action.payload
    },
    setAddPhonogramsInPlaylistFromServer(state: TCatalog, action: PayloadAction<Array<TMixedPlaylistPhonograms>>) {
      state.playlistPhonograms = action.payload
    },
    setAddPhonogramInPlaylist(state: TCatalog, action: PayloadAction<TPhonogram>) {
      state.playlistPhonograms.push(action.payload)
    },
    setRemovePhonogramFromPlaylist(state: TCatalog, action: PayloadAction<string | null>) {
      state.playlistPhonograms = state.playlistPhonograms.filter((item) => item.id !== action.payload)
    },
    setTabIndex(state: TCatalog, action: PayloadAction<number>) {
      state.tabIndex = action.payload
    },
  },
})

export const {
  setPlaylistsOks,
  setPlaylistsLk,
  setGenreList,
  setPhonogramsList,
  setPhonogramsListOnScroll,
  setPhonogramsLoadOffset,
  setPhonogramsListHasMore,
  setRemovePhonogramFromBlacklist,
  setAddingPhonogramIds,
  setDeletingPhonogramIds,
  clearAddingDeletingPhonogramIds,
  setPhonogramsInBlacklistFromServer,
  setAddPhonogramInBlacklist,
  setPlaylistID,
  setPlaylistById,
  setAddPhonogramsInPlaylistFromServer,
  setAddPhonogramInPlaylist,
  setRemovePhonogramFromPlaylist,
  setTabIndex,
} = catalogs.actions

export default catalogs.reducer
