import { Manager } from 'socket.io-client'
import { config } from 'config/config'

export const manager = new Manager(config.baseUrl)

export const socket = manager.socket('/', {}).disconnect()

export enum wsEmit {
  JoinToPlaybackQueue = 'JoinToPlaybackQueue',
  AddItemInSecondPosition = 'AddItemInSecondPosition',
  AddItemInLastPosition = 'AddItemInLastPosition',
  MoveQueueItem = 'MoveQueueItem',
  RemoveQueueItem = 'RemoveQueueItem',
  ClearPlaybackQueue = 'ClearPlaybackQueue',
}
export enum wsOn {
  PlaybackQueueReceived = 'PlaybackQueueReceived',
  PlaybackQueueUpdated = 'PlaybackQueueUpdated',
  ErrorOccured = 'ErrorOccured',
  exception = 'exception',
}
