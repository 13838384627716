import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TCreateClient, TRegistrationState } from './types'

const initialState: TRegistrationState = {
  regFormData: null,
}

const userRegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegFormData(state: TRegistrationState, action: PayloadAction<TCreateClient | null>) {
      state.regFormData = action.payload
    },
  },
})

export const { setRegFormData } = userRegistrationSlice.actions

export default userRegistrationSlice.reducer
