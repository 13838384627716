import { request } from 'api'
import { AppDispatch } from 'store/store'
import { AppDocumentRes, AppDocumentTypes } from './types'
import { setVideoInstructionLink } from './reducer'
import { AxiosResponse } from 'axios'

export const getDocumentByType = (documentType: AppDocumentTypes) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get<AxiosResponse<AppDocumentRes>>(`/documentation/${documentType}`)
      if (documentType === AppDocumentTypes.videoInstruction) {
        dispatch(setVideoInstructionLink(response.data.data.externalUrl))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
