import { request } from 'api'
import { AppDispatch } from 'store/store'
import { myLicensesList, setLicenseById, setMyLicense, setPaymentURL } from './reducer'
import { requestUserPayment } from 'api/paymentTinkoff'
import axios from 'axios'

export const getMyLicenseFromServer = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/licenses/my`)
      dispatch(setMyLicense(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getMyLicensesListFromServer = (isActual: boolean) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/licenses`, {
        params: {
          isActual,
        },
      })
      dispatch(myLicensesList(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getLicenseByIdFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/licenses/${id}`)
      dispatch(setLicenseById(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

//cost передавать в копейках!!!!!!!!
export const buyLicense = (tariffId: string, duration: number, cost: number, email: string, tariffName: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.post(`/orders/tariff`, { tariffId, duration, cost })
      const tinkoffResponse = await axios.post('https://securepay.tinkoff.ru/v2/Init', {
        ...requestUserPayment(response.data.data.id, cost, email, tariffName),
      })
      dispatch(setPaymentURL(tinkoffResponse.data.PaymentURL))
      dispatch(setMyLicense(null))
    } catch (error) {
      console.log(error)
    }
  }
}

//cost передавать в копейках!!!!!!!!
export const buyLicenseByInvoicing = (
  tariffId: string,
  duration: number,
  cost: number,
  discountId: string | undefined,
  showPopUp: () => void,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.post(`/orders/invoice`, { tariffId, duration, cost, discountId })
      dispatch(setMyLicense(null))
      showPopUp()
    } catch (error) {
      console.log(error)
    }
  }
}
