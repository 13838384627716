export const main_colors = {
  background: {
    primary: '#8441FF',
    secondary: '#FFFFFF',
    cards: '#F8F9FB',
    items: '#FAF9FF',
    activeItems: '#EBE7FB',
  },
  text: {
    primary: '#03000E',
    secondary: '#3E3E3F',
    tertiary: '#959598',
    violet: '#8441FF',
    gold: '#FFEC00',
    white: '#FFFFFF',
    red: 'red',
    blue: 'blue',
    green: '#27ae60',
    lightGreen: 'rgba(39, 174, 96, 0.1)',
  },
  button: {
    background: {
      enabled: '#8441FF',
      disabled: '#E0E0E0',
    },
    text: {
      enabled: '#F5F5F5',
      disabled: '#BDBDBD',
    },
    inverse: {
      background: {
        enabled: '#E0E0E0',
      },
      text: {
        enabled: '#8441FF',
      },
    },
  },
  border: {
    primary: '#e1e9f0',
    focus: '#8441FF',
    violet: '#6714FF',
  },
  auth: {
    text: {
      primary: '#FFFFFF',
      secondary: '#F5F5F5',
    },
    button: {
      background: '#FFFFFF',
      text: '#8441FF',
    },
    border: '#FFFFFF',
  },
  error: {
    text: 'red',
  },
  table: {
    item: {
      background: {
        default: '#ffffff',
        odd: '#FAF9FF',
        playing: '#EBE7FB',
      },
    },
  },
}
