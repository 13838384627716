import React, { useEffect } from 'react'
import { Routes } from 'routes/routes'
import { Route, Redirect } from 'react-router-dom'
import { FC } from 'react'
import { LocalStorageKey } from 'store/modules/auth/types'
import { getProfileFromServer } from 'store/modules/profile/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from 'store/modules/profile/selectors'

type TProps = {
  path?: Routes | string
  exact?: boolean
}

export const PrivateRoute: FC<TProps> = ({ children, ...props }) => {
  const dispatch = useDispatch()
  const isToken = Boolean(localStorage.getItem(LocalStorageKey.key))
  const profile = useSelector(getProfile)

  // const accessIsAllowed = profile?.phone.isVerified && myLicense
  // console.log('private', accessIsAllowed)

  useEffect(() => {
    if (!profile) {
      dispatch(getProfileFromServer())
    }
  }, [profile])

  if (!profile) {
    return <></>
  }

  return (
    <>
      <Route
        {...props}
        render={({ location }) =>
          isToken ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: Routes.auth,
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  )
}
