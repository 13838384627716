import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TTariff, TTariffsState } from './types'

const initialState: TTariffsState = {
  tariffsList: [],
  tariffById: null,
}

const userSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setTariffsList(state: TTariffsState, action: PayloadAction<TTariff[]>) {
      state.tariffsList = action.payload
    },
    setTariffById(state: TTariffsState, action: PayloadAction<TTariff | null>) {
      state.tariffById = action.payload
    },
  },
})

export const { setTariffsList, setTariffById } = userSlice.actions

export default userSlice.reducer
