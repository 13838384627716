import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TDevice, TDevicesState } from 'store/modules/devices/types'

const initialState: TDevicesState = {
  devices: [],
  device: null,
  isCreated: false,
}

const userEditSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices(state: TDevicesState, action: PayloadAction<TDevice[]>) {
      state.devices = action.payload
    },
    setDevice(state: TDevicesState, action: PayloadAction<TDevice | null>) {
      state.device = action.payload
    },
  },
})

export const { setDevices, setDevice } = userEditSlice.actions

export default userEditSlice.reducer
