import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAppDocumentSate } from './types'

const initialState: TAppDocumentSate = {
  videoInstructionLink: null,
}

const appDocumentsSlice = createSlice({
  name: 'appDocuments',
  initialState,
  reducers: {
    setVideoInstructionLink(state: TAppDocumentSate, action: PayloadAction<string | null>) {
      state.videoInstructionLink = action.payload
    },
  },
})

export const { setVideoInstructionLink } = appDocumentsSlice.actions

export default appDocumentsSlice.reducer
