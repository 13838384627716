if (!process.env.REACT_APP_BASE_URL) {
  throw new Error('Please specify REACT_APP_BASE_URL env variable')
}

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  standardCover: 'https://storage.theoryandpractice.ru/tnp/uploads/image_unit/000/156/586/image/base_87716f252d.jpg',
  terminalKey: process.env.REACT_APP_TERMINAL_KEY,
  notificationURL: process.env.REACT_APP_NOTIFICATION_URL,
  storageBaseUrl: process.env.REACT_APP_STORAGE_BASE_URL,
  daDataAPIKey: process.env.REACT_APP_DADATA_API_KEY,
}
