import React from 'react'
import { css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { main_colors } from 'styles/colors'

const overrideSpinnerStyles = css`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30px;
  right: 0;
  z-index: 100;
`

type TProps = {
  showSpinner: boolean
  size?: number
}

export const Loader = (props: TProps) => (
  <ClipLoader
    css={overrideSpinnerStyles}
    size={props.size || 100}
    color={main_colors.text.violet}
    loading={props.showSpinner}
  />
)
