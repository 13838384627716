import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TFaqItem, TSupportState, TSupportSubject } from 'store/modules/support/types'

const initialState: TSupportState = {
  supportSubjectsList: [],
  faqList: [],
}

const userSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setSupportSubjectsList(state: TSupportState, action: PayloadAction<TSupportSubject[]>) {
      state.supportSubjectsList = action.payload
    },
    setFaqList(state: TSupportState, action: PayloadAction<TFaqItem[]>) {
      state.faqList = action.payload
    },
  },
})

export const { setSupportSubjectsList, setFaqList } = userSlice.actions

export default userSlice.reducer
