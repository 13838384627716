import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TUser, TUserState } from 'store/modules/profile/types'
import { TSpot } from '../spots/types'

const initialState: TUserState = {
  data: null,
}

const userEditSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state: TUserState, action: PayloadAction<TUser | null>) {
      state.data = action.payload
    },
    setDefaultEntertainmentCenter(state: TUserState, action: PayloadAction<TSpot | undefined>) {
      if (state.data?.company) {
        state.data.company.defaultEntertainmentCenter = action.payload
      }
    },
    setAddressMatch(state: TUserState, action: PayloadAction<boolean>) {
      if (state.data?.company) {
        state.data.company.isAddressMatched = action.payload
      }
    },
  },
})

export const { setProfile, setDefaultEntertainmentCenter, setAddressMatch } = userEditSlice.actions

export default userEditSlice.reducer
