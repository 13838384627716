import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRoom, TRoomInList, TSpot, TSpotInList, TSpotsState, TTable, TTableInList } from 'store/modules/spots/types'
import { TDevice } from 'store/modules/devices/types'

const initialState: TSpotsState = {
  spot: null,
  room: null,
  table: null,
  tables: [],
  spots: [],
  rooms: [],
  spotId: null,
  roomId: null,
  tableId: null,
  showSpotBlock: false,
  showRoomBlock: false,
  showTableBlock: false,
  unBindDevices: [],
}

const userSlice = createSlice({
  name: 'spots',
  initialState,
  reducers: {
    setSpot(state: TSpotsState, action: PayloadAction<TSpot | null>) {
      state.spot = action.payload
    },
    setRoom(state: TSpotsState, action: PayloadAction<TRoom | null>) {
      state.room = action.payload
    },
    setTable(state: TSpotsState, action: PayloadAction<TTable | null>) {
      state.table = action.payload
    },
    setSpots(state: TSpotsState, action: PayloadAction<TSpotInList[]>) {
      state.spots = action.payload
    },
    setTables(state: TSpotsState, action: PayloadAction<TTableInList[]>) {
      state.tables = action.payload
    },
    setRooms(state: TSpotsState, action: PayloadAction<TRoomInList[]>) {
      state.rooms = action.payload
    },
    setSpotId(state: TSpotsState, action: PayloadAction<string | null>) {
      state.spotId = action.payload
    },
    setRoomId(state: TSpotsState, action: PayloadAction<string | null>) {
      state.roomId = action.payload
    },
    setTableId(state: TSpotsState, action: PayloadAction<string | null>) {
      state.tableId = action.payload
    },
    setShowSpotBlock(state: TSpotsState, action: PayloadAction<boolean>) {
      state.showSpotBlock = action.payload
    },
    setShowRoomBlock(state: TSpotsState, action: PayloadAction<boolean>) {
      state.showRoomBlock = action.payload
    },
    setShowTableBlock(state: TSpotsState, action: PayloadAction<boolean>) {
      state.showTableBlock = action.payload
    },
    setUnbindDevices(state: TSpotsState, action: PayloadAction<TDevice[]>) {
      state.unBindDevices = action.payload
    },
  },
})

export const {
  setSpot,
  setSpots,
  setRooms,
  setRoom,
  setSpotId,
  setRoomId,
  setTableId,
  setTable,
  setTables,
  setShowSpotBlock,
  setShowRoomBlock,
  setShowTableBlock,
  setUnbindDevices,
} = userSlice.actions

export default userSlice.reducer
