export type TAppDocumentSate = {
  videoInstructionLink: null | string
}

export enum AppDocumentTypes {
  videoInstruction = 'lk-user-instruction',
}

export type AppDocumentRes = {
  id: string
  type: string
  name: string
  accept: string
  uploadedBy: {
    firstName: string
    fatherName: string
    lastName: string
  }
  file: {
    id: string
    createdAt: string
    updatedAt: string
    deletedAt: string
    uploadedBy: number
    relativePath: string
    mimeType: string
    size: number
    originalName: string
    uploadedAt: string
    removedAt: string
  }
  updatedAt: string
  externalUrl: string
}
