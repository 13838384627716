import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPlayQueueState, TSong } from 'store/modules/playQueue/types'

const initialState: TPlayQueueState = {
  queue: [],
  playerId: null,
  isSocketConnected: false,
}

const userEditSlice = createSlice({
  name: 'playQueue',
  initialState,
  reducers: {
    setQueue(state: TPlayQueueState, action: PayloadAction<TSong[]>) {
      state.queue = action.payload
    },
    setPlayerId(state: TPlayQueueState, action: PayloadAction<string | null>) {
      state.playerId = action.payload
    },
    setIsSocketConnected(state: TPlayQueueState, action: PayloadAction<boolean>) {
      state.isSocketConnected = action.payload
    },
  },
})

export const { setQueue, setPlayerId, setIsSocketConnected } = userEditSlice.actions

export default userEditSlice.reducer
