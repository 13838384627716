import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeZone } from './types'

type TState = {
  timzones: TimeZone[]
}

const initialState: TState = {
  timzones: [],
}

const timzoneSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setTimeZones(state: TState, action: PayloadAction<TimeZone[]>) {
      state.timzones = action.payload
    },
  },
})

export const { setTimeZones } = timzoneSlice.actions

export default timzoneSlice.reducer
