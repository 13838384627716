import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TLicensesState, TMyLicense } from './types'

const initialState: TLicensesState = {
  myLicense: null,
  licenseById: null,
  myLicensesList: [],
  paymentURL: null,
}

const userSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setMyLicense(state: TLicensesState, action: PayloadAction<TMyLicense>) {
      state.myLicense = action.payload
    },
    myLicensesList(state: TLicensesState, action: PayloadAction<TMyLicense[]>) {
      state.myLicensesList = action.payload
    },
    setLicenseById(state: TLicensesState, action: PayloadAction<TMyLicense>) {
      state.licenseById = action.payload
    },
    setPaymentURL(state: TLicensesState, action: PayloadAction<string | null>) {
      state.paymentURL = action.payload
    },
  },
})

export const { setMyLicense, setLicenseById, myLicensesList, setPaymentURL } = userSlice.actions

export default userSlice.reducer
