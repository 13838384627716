import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TChart, TChartPhonogram, TChartsState } from './types'

const initialState: TChartsState = {
  chartsList: [],
  chartPhonogramsList: [],
  chartId: null,
  chartById: null,
  chartByIdIsDeleted: false,
}

const userSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    setChartPhonogramsList(state: TChartsState, action: PayloadAction<TChartPhonogram[]>) {
      state.chartPhonogramsList = action.payload
    },
    setChartList(state: TChartsState, action: PayloadAction<TChart[]>) {
      state.chartsList = action.payload
    },
    setChartId(state: TChartsState, action: PayloadAction<string | null>) {
      state.chartId = action.payload
    },
    setChartById(state: TChartsState, action: PayloadAction<TChart | null>) {
      state.chartById = action.payload
    },
    setChartByIdIsDeleted(state: TChartsState, action: PayloadAction<boolean>) {
      state.chartByIdIsDeleted = action.payload
    },
  },
})

export const {
  setChartPhonogramsList,
  setChartList,
  setChartId,
  setChartById,
  setChartByIdIsDeleted,
} = userSlice.actions

export default userSlice.reducer
