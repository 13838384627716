import React, { useEffect } from 'react'
import { RootRoute } from 'routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoading } from 'store/modules/loader/selectors'
import { Loader } from 'components/loader'
import { getProfile } from 'store/modules/profile/selectors'
import { socket } from 'webSocket'
import { LocalStorageKey } from 'store/modules/auth/types'
import { getMyLicenseFromServer } from 'store/modules/licenses/actions'
import { setIsSocketConnected } from 'store/modules/playQueue/reducer'
import { getIsSocketConnected } from 'store/modules/playQueue/selectors'
import styled from 'styled-components'
import 'react-dadata/dist/react-dadata.css'
import { getDocumentByType } from 'store/modules/appDocuments/actions'
import { AppDocumentTypes } from 'store/modules/appDocuments/types'

export function App() {
  const isLoading = useSelector(getIsLoading)
  const profile = useSelector(getProfile)
  const dispatch = useDispatch()
  const isSocketConnected = useSelector(getIsSocketConnected)

  useEffect(() => {
    // const loadingScreen = document.querySelector('#await-app-loading-screen')
    // if (loadingScreen) {
    //   loadingScreen.remove()
    // }
    socket.on('connect', () => {
      dispatch(setIsSocketConnected(true))
    })
    socket.on('disconnect', () => {
      dispatch(setIsSocketConnected(false))
    })

    dispatch(getDocumentByType(AppDocumentTypes.videoInstruction))
  }, [])

  useEffect(() => {
    if (profile) {
      dispatch(getMyLicenseFromServer())

      socket.auth = { token: localStorage.getItem(LocalStorageKey.key) }
      socket.io.opts.extraHeaders = {
        Authorization: `Bearer ${localStorage.getItem(LocalStorageKey.key)}`,
      }
      socket.connect()
    }
  }, [profile])

  return (
    <>
      {isLoading && <Loader showSpinner={isLoading} />}
      <SocketIndicator $isSocketConnected={isSocketConnected} />
      <ToastContainer />
      <RootRoute />
    </>
  )
}

const SocketIndicator = styled.div<{ $isSocketConnected: boolean }>`
  width: 2px;
  height: 2px;
  position: fixed;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: ${(props) => (props.$isSocketConnected ? 'green' : 'red')};
`
