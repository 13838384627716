import { request } from 'api'
import { AppDispatch } from 'store/store'
import { setAddressMatch, setDefaultEntertainmentCenter, setProfile } from './reducer'
import { TUpdateUser } from 'store/modules/profile/types'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { config } from 'config/config'

export const editProfile = (data: TUpdateUser, id: string, goBack: () => void) => {
  return (dispatch: AppDispatch) => {
    request
      .put(`/users/clients/${id}`, data)
      .then((r) => dispatch(setProfile(r.data.data)))
      .then(() => goBack())
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const saveAddressMatch = (isMatch: boolean) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await request.put('/users/clients/address-match', { isAddressMatched: isMatch })
      if (res) {
        dispatch(setAddressMatch(isMatch))
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const updateDefaultEntertainmentCenter = (
  id: string,
  addressData: DaDataSuggestion<DaDataAddress>,
  onSuccess?: () => void,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await request.put(`/lk/entertainment-centers/${id}`, { addressDetails: addressData })
      const data = res.data.data
      dispatch(setDefaultEntertainmentCenter(data))
      onSuccess && onSuccess()
    } catch (error) {
      console.log(error)
    }
  }
}

// export const saveAddressMatch2 = createAsyncThunk<void, boolean, { state: TState }>(
//   'users/saveAddressMatch',
//   async (isMatch: boolean, { getState, dispatch }) => {
//     try {
//       const res = await request.put('/users/clients/address-match', { isAddressMatched: isMatch })
//       if (res) {
//         dispatch(setAddressMatch(isMatch))
//       }
//     } catch (e) {
//       console.log(e)
//     }
//   },
// )

export const changePassword = (password: string, id: string, fn: () => void) => {
  return async () => {
    try {
      await request.put(`/users/clients/${id}`, { password: password })
      fn()
    } catch (e) {
      console.log(e)
    }
  }
}

export const getProfileFromServer = () => {
  return (dispatch: AppDispatch) => {
    request
      .get('/users/clients/me')
      .then(function (response) {
        const data = response.data.data
        dispatch(setProfile(data))
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const uploadContractFileOnServer = (contractId: string, files: FileList) => {
  return async (dispatch: AppDispatch) => {
    const formData = new FormData()
    formData.append('file', files[0])
    try {
      await request.post(`/contracts/${contractId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(getProfileFromServer() as any)
    } catch (error) {
      console.log(error)
    }
  }
}

// export const createContractOnServer = () => {
//   return async (dispatch: AppDispatch) => {
//     try {
//       await request.post(`/contracts`)
//       dispatch(getProfileFromServer())
//     } catch (error) {
//       console.log(error)
//     }
//   }
// }

export const sendToUserPhoneConfirmCode = (callback?: () => void) => {
  return async () => {
    try {
      await request.post(`/users/clients/phone`)
      if (callback) callback()
    } catch (e) {
      console.log(e)
    }
  }
}

export const confirmPhone = (code: string, callback?: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.post(`/users/clients/phone/verify`, { code })
      dispatch(getProfileFromServer() as any)
    } catch (e) {
      console.log(e)
    } finally {
      if (callback) callback()
    }
  }
}

export const sendOnboardingProcessCompletedStatus = (callback: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.patch(`/users/clients/onboarding/complete`)
      const profileResponse = await request.get('/users/clients/me')
      const data = profileResponse.data.data
      dispatch(setProfile(data))
      callback()
    } catch (error) {
      console.log(error)
    }
  }
}

export const getUserInstructionFromServer = () => {
  return async () => {
    try {
      const resPath = await request.get('/documentation/user-instruction')
      const link = document.createElement('a')
      link.href = config.storageBaseUrl + resPath.data.data.file.relativePath
      link.target = '_blank'
      link.setAttribute('download', '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }
}
