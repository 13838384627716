import { toast } from 'react-toastify'
import { TError$ } from 'store/modules/error/types'
import { messagesConverter } from 'helpers/errorMessagesConverter'

export const errorNotify = (error: TError$) => {
  if (error.response.data.statusCode === 401 || error.response.data.error === 'Access denied') return

  if (error.response && error.response.data) {
    toast.error(messagesConverter(error.response.data.error))
    if (error.response.data.message && Array.isArray(error.response.data.message)) {
      error.response.data.message.forEach((m: string) => toast.error(messagesConverter(m)))
    } else if (error.response.data.message) {
      toast.error(messagesConverter(error.response.data.message))
    }
  } else {
    toast.error('Ooops...')
  }
}
