import React, { FC, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from 'routes/private-route'
import { Routes } from 'routes/routes'
import { Loader } from 'components/loader'
import NoFound from 'pages/404'
const RegistrationPage = lazy(() => import('pages/registration'))
const ConfirmPhoneNumber = lazy(() => import('pages/confirmPhoneNumber'))
const ProfilePage = lazy(() => import('pages/profile'))
const CataloguesPage = lazy(() => import('pages/catalogs'))
const RestorePasswordPage = lazy(() => import('pages/restorePassword'))
const PlayersPage = lazy(() => import('pages/devices'))
const UsersPage = lazy(() => import('pages/users'))
const UserEditPage = lazy(() => import('pages/users/userEdit'))
const UserCreatePage = lazy(() => import('pages/users/userCreate'))
const StatisticsPage = lazy(() => import('pages/statistics'))
const AvailableUpdatesPage = lazy(() => import('pages/availableUpdates'))
const TariffsPage = lazy(() => import('pages/tariffs'))
const TariffPage = lazy(() => import('pages/tariff'))
const LicensePage = lazy(() => import('pages/license'))
const SupportPage = lazy(() => import('pages/support'))
const UserForm = lazy(() => import('pages/profile/editProfile'))
const BlackListEdit = lazy(() => import('pages/catalogs/playlists/blacklist'))
const PlaylistLK = lazy(() => import('pages/catalogs/playlists/playlistLK'))
const PlaylistOKC = lazy(() => import('pages/catalogs/playlists/playlistOKC'))
const SpotsPage = lazy(() => import('pages/spots'))
const DevicePage = lazy(() => import('pages/device'))
const PlayQueuePage = lazy(() => import('pages/playQueue'))
const LoginPage = lazy(() => import('pages/login'))
const ChartPage = lazy(() => import('pages/catalogs/charts/chart'))
const OnboardingPage = lazy(() => import('pages/onboarding'))
const AppPage = lazy(() => import('pages/app'))

export const RootRoute: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader showSpinner={true} />}>
        <Switch>
          <Route path={Routes.auth}>
            <LoginPage />
          </Route>

          <Route path={Routes.signup}>
            <RegistrationPage />
          </Route>

          <Route path={Routes.confirm}>
            <ConfirmPhoneNumber />
          </Route>

          <Route path={Routes.restorePassword}>
            <RestorePasswordPage />
          </Route>

          <Route path={Routes.onboarding}>
            <OnboardingPage />
          </Route>

          <PrivateRoute exact path={Routes.profile}>
            <ProfilePage />
          </PrivateRoute>

          <PrivateRoute path={Routes.profileEdit}>
            <UserForm />
          </PrivateRoute>

          <PrivateRoute exact path={Routes.users}>
            <UsersPage />
          </PrivateRoute>

          <PrivateRoute exact path={Routes.newUser}>
            <UserCreatePage />
          </PrivateRoute>

          <PrivateRoute exact path={Routes.editUser.toString() + '/:id'}>
            <UserEditPage />
          </PrivateRoute>

          <PrivateRoute exact path={Routes.editDevice.toString() + '/:id'}>
            <DevicePage />
          </PrivateRoute>

          <PrivateRoute path={Routes.players}>
            <PlayersPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.catalogues}>
            <CataloguesPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.statistics}>
            <StatisticsPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.playQueue}>
            <PlayQueuePage />
          </PrivateRoute>

          <PrivateRoute path={Routes.tariffs}>
            <TariffsPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.availableUpdates}>
            <AvailableUpdatesPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.tariff.toString() + '/:id'}>
            <TariffPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.license.toString() + '/:id'}>
            <LicensePage />
          </PrivateRoute>

          <PrivateRoute path={Routes.chart + '/:id?'}>
            <ChartPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.support}>
            <SupportPage />
          </PrivateRoute>

          <PrivateRoute path={Routes.blacklistEdit}>
            <BlackListEdit />
          </PrivateRoute>

          <PrivateRoute path={Routes.playlistLK + '/:id?'}>
            <PlaylistLK />
          </PrivateRoute>

          <PrivateRoute path={Routes.playlistOKC + '/:id?'}>
            <PlaylistOKC />
          </PrivateRoute>

          <PrivateRoute exact path={Routes.spots}>
            <SpotsPage />
          </PrivateRoute>

          <Route path={Routes.app}>
            <AppPage />
          </Route>

          <PrivateRoute>
            <NoFound />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}
