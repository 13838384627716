export enum Routes {
  profile = '/',
  profileEdit = '/profileEdit',
  signup = '/signup',
  confirm = '/confirm',
  auth = '/auth',
  restorePassword = '/restorePassword',
  users = '/users',
  newUser = '/users/add',
  editUser = '/users/edit',
  players = '/players',
  editDevice = '/players/edit',
  catalogues = '/catalogues',
  statistics = '/statistics',
  tariffs = '/tariffs',
  availableUpdates = '/availableUpdates',
  tariff = '/tariff',
  license = '/license',
  myPurchaseDetail = '/myPurchaseDetail',
  catalogUpdateDetail = '/catalogUpdateDetail',
  chart = '/chart',
  support = '/support',
  blacklistEdit = '/blacklistEdit',
  playlistLK = '/playlist-lk',
  playlistOKC = '/playlist-okc',
  spots = '/spots',
  playQueue = '/play-queue',
  onboarding = '/onboarding',
  app = '/app',
  // createSpot = '/spots/create',
  noMatch = '*',
}
