import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Routes } from 'routes/routes'

const NoFound = () => {
  return (
    <Container>
      <Text>Запрашиваемая страница не найдена</Text>
      <Link to={Routes.profile}>На главную</Link>
    </Container>
  )
}

export default NoFound

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Text = styled.p`
  font-size: 24px;
  font-weight: 700;
`
