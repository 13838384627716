import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPhonogramForStatisticsPage, TStatisticsState } from './types'
import { TRoomInList } from 'store/modules/spots/types'

const initialState: TStatisticsState = {
  statisticsPhonogramList: [],
  spotsRooms: [],
}

const userSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatisticsPhonogramList(state: TStatisticsState, action: PayloadAction<TPhonogramForStatisticsPage[]>) {
      state.statisticsPhonogramList = action.payload
    },
    setSpotsRooms(state: TStatisticsState, action: PayloadAction<TRoomInList[]>) {
      state.spotsRooms = action.payload
    },
  },
})

export const { setStatisticsPhonogramList, setSpotsRooms } = userSlice.actions

export default userSlice.reducer
