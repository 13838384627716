import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type TState = {
  isMobile: boolean
}

const initialState: TState = {
  isMobile: window.innerWidth <= 1024,
}

const adaptiveSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setIsMobile(state: TState, action: PayloadAction<boolean>) {
      state.isMobile = action.payload
    },
  },
})

export const { setIsMobile } = adaptiveSlice.actions

export default adaptiveSlice.reducer
