import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPlayerUser, TUsersState } from 'store/modules/playerUsers/types'

const initialState: TUsersState = {
  user: null,
  users: [],
}

const playerUserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser(state: TUsersState, action: PayloadAction<TPlayerUser | null>) {
      state.user = action.payload
    },
    setUsers(state: TUsersState, action: PayloadAction<TPlayerUser[]>) {
      state.users = action.payload
    },
  },
})

export const { setUser, setUsers } = playerUserSlice.actions

export default playerUserSlice.reducer
