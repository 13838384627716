import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TGeoName, TGeoState, TGeoType } from 'store/modules/geoData/types'

const initialState: TGeoState = {
  geoTypesList: [],
  geoNamesList: [],
}

const userSlice = createSlice({
  name: 'geoData',
  initialState,
  reducers: {
    setGeoTypesList(state: TGeoState, action: PayloadAction<TGeoType[]>) {
      state.geoTypesList = action.payload
    },
    setGeoNamesList(state: TGeoState, action: PayloadAction<TGeoName[]>) {
      state.geoNamesList = action.payload
    },
  },
})

export const { setGeoTypesList, setGeoNamesList } = userSlice.actions

export default userSlice.reducer
