import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Discount, TCalculateResult } from './types'

type TState = {
  discounts: Discount[]
  selectedDiscountId: string
  calculateResult: TCalculateResult | null
}

const initialState: TState = {
  discounts: [],
  selectedDiscountId: '',
  calculateResult: null,
}

const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setDiscounts(state: TState, action: PayloadAction<Discount[]>) {
      state.discounts = action.payload
    },
    setSelectedDiscountId(state: TState, action: PayloadAction<string>) {
      state.selectedDiscountId = action.payload
    },
    setCalculateResult(state: TState, action: PayloadAction<TCalculateResult | null>) {
      state.calculateResult = action.payload
    },
  },
})

export const { setDiscounts, setSelectedDiscountId, setCalculateResult } = calculatorSlice.actions

export default calculatorSlice.reducer
